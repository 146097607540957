import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import cx from 'classnames'
import Icon from '@components/icon'
import Photo from '@components/photo'
import { useI18nContext } from '@lib/i18n'

const pressList = ({ data = {} }) => {
  const { press, featuredOnly } = data
  if (!press.length) return null

  const { t } = useI18nContext()
  return (
    <section
      className={cx('press-list', { 'press-list--featured': featuredOnly })}
    >
      {featuredOnly ? (
        <div className="press-list-featured">
          <h5 className="press-list-featured--heading">
            {t('In the press', 'In the press')}
          </h5>
          <div className="press-list-featured--logos">
            {press
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((r) => r.featured)
              .map(({ website, logo }, i) => (
                <div className="press-list-featured--press" key={i}>
                  <a
                    href={website}
                    target="_blank"
                    className="press-list-featured--logo"
                  >
                    <Photo photo={logo} className="press-list-featured--logo" />
                  </a>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="press-text-list">
          <h5>{t('In the press', 'In the press')}</h5>
          <div className="press-text-list--outlets">
            {press
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ website, name, address, excerpts = [] }, i) => (
                <div className="press-text-list--press" key={i}>
                  <h5 className="press-text-list--name">
                    <a href={website} target="_blank">
                      {name}
                    </a>
                  </h5>
                  <BlockContent
                    className="press-text-list--address"
                    renderContainerOnSingleChild
                    blocks={address}
                    serializers={serializers}
                  />

                  {excerpts &&
                    excerpts.map((excerpt, i) => (
                      <div className="press-text-list--excerpt" key={i}>
                        <span className="press-text-list--date">
                          {excerpt.date}
                        </span>
                        <p className="press-text-list--description">
                          {excerpt.description}
                          <br />
                          <a
                            href={excerpt.url}
                            className="press-text-list--read-more"
                          >
                            Read more{' '}
                            <Icon viewBox="0 0 24 24" name="ChevronRight" />
                          </a>
                        </p>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default pressList
